/* You can add global styles to this file, and also import other style files */

@import 'assets/plugins/bootstrap/css/bootstrap.css';
@import'assets/css/style.default.css';
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import 'assets/css/animate.css';
html,
body {
    height: 100%;
}

body {
    /* margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    overflow: hidden; */
    /* height: 100%; */
    margin: 0;
    font-family: "Open Sans" !important;
    overflow: hidden;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    pointer-events: none !important;
}

.custom-warn {
    background-color: rgb(245, 172, 36) !important;
    color: #fff !important;
}

.custom-success {
    background-color: rgb(86, 196, 23) !important;
    color: #fff !important;
}

.custom-info {
    background-color: rgb(100, 204, 252)!important;
    color: #fff !important;
}

.custom-error {
    background-color: rgb(247, 86, 58) !important;
    color: #fff !important;
}

.mat-mdc-snack-bar-action button {
    max-height: 36px;
    min-width: 0;
    color: #fff !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface{
    background-color: transparent !important;
  }
  .mat-mdc-select-trigger{
    padding: 0 0.5rem;
  }
  mat-option{
    border-bottom: 1px solid #ddd;
    font-size: 13px !important;
  }
  .mat-mdc-checkbox label{
    margin: 0 !important;
}
.mdc-menu-surface.mat-mdc-select-panel {
    padding-top: 0 !important;
}
.mdc-menu-surface.mat-mdc-select-panel .search-input {
    padding-top: 5px !important;
    background-color: white;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.leaflet-top{
  z-index: 401 !important;
}
