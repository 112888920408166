    /********** 2. RESET STYLES **********/
    /*************************************/
    
    @import url('https://fonts.googleapis.com/css?family=Noto+Sans');
    /* Loader start */
    
    .open-sans {
        font-family: 'Open Sans'!important;
    }
    
    .form-select-md {
        border: 1px solid #dbdeea;
        height: 30px;
    }
    
    .hidden {
        display: none!important;
    }
    
    .loader-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999;
    }
    
    .create-master-panel .mat-mdc-dialog-container {
        padding: 0;
        margin: 0;
        /* height: 700px!important; */
        overflow: inherit;
    }
    
    .create-master-panel .mat-mdc-dialog-container .mat-mdc-dialog-content {
        padding: 10px 30px;
        margin: 0;
    }
    
    .create-master-select .mat-mdc-dialog-container {
        padding: 0;
        margin: 0;
        height: auto;
        overflow: inherit;
    }
    
    .create-master-select .mat-mdc-dialog-container .mat-mdc-dialog-content {
        padding: 10px 30px;
        margin: 0;
    }
    
    .f-14 {
        font-size: 14px!important;
    }
    
    .mat-mdc-menu-content {
        padding: 0px !important;
    }
    
    .mat-mdc-menu-panel {
        min-height: 0px!important;
    }
    
    .heading {
        color: #616473;
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    .lds-spinner {
        color: official;
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    
    .lds-spinner div {
        transform-origin: 32px 32px;
        animation: lds-spinner 1.2s linear infinite;
    }
    
    .lds-spinner div:after {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 29px;
        width: 5px;
        height: 14px;
        border-radius: 20%;
        background: #16cded;
    }
    
    .lds-spinner div:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
    }
    
    .lds-spinner div:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
    }
    
    .lds-spinner div:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
    }
    
    .lds-spinner div:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
    }
    
    .lds-spinner div:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
    }
    
    .lds-spinner div:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
    }
    
    .lds-spinner div:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
    }
    
    .lds-spinner div:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
    }
    
    .lds-spinner div:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
    }
    
    .lds-spinner div:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
    }
    
    .lds-spinner div:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
    }
    
    .lds-spinner div:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
    }
    
    @keyframes lds-spinner {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    /* Loader End */
    
    body {
        font-size: 13px;
        color: #636E7B;
        background-color: #fff;
        font-family: 'Noto Sans', sans-serif!important;
    }
    
    a {
        outline: none !important;
    }
    
    a:hover,
    a:active,
    a:focus {
        text-decoration: none;
    }
    
    .full-screen-preview {
        height: 100%;
        padding: 0px;
        margin: 0px;
        overflow: hidden;
    }
    
    .full-screen-preview .mat-drawer-container {
        height: 100%;
        background-color: #f6f6f6;
    }
    
    .w-100 {
        width: 100%;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
    .mat-menu-user .mat-mdc-menu-content {
        padding: 0;
    }
    
    .rounded {
        border-radius: 50%;
    }
    
    .divider {
        width: 100%;
        height: 1px;
        background: #efefef;
    }
    
    .app.fixed-header .mat-drawer-content {
        overflow: unset;
    }
    
    .ml-1 {
        margin-left: 1em!important;
    }
    
    .mr-1 {
        margin-right: 1em!important;
    }
    
    .middle-content {
        position: absolute;
        top: 56px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 8px;
        min-height: calc(100% - 72px);
        touch-action: auto;
        background-color: rgb(246, 246, 246);
    }
    
    @media (max-width: 958px) {
        .middle-content {
            padding: 0;
        }
        .full-screen-preview .mat-drawer-container {
            height: 100%;
            background-color: #ffffff;
        }
    }
    
    .table-estater {
        max-height: 450px;
        overflow: auto;
    }
    
    .table-estater mat-table {
        background-color: transparent;
    }
    
    .table-estater mat-row {
        margin: 5px 0;
        background-color: white;
        padding: 0;
        min-height: 0px;
        align-items: stretch;
        background-color: white;
        border: 1px solid #dbddea;
    }
    
    .table-estater mat-row:hover,
    .table-estater mat-row.active {
        background-color: #23cb6b;
        color: white;
    }
    
    .table-estater mat-row:hover mat-cell {
        color: white;
    }
    
    .table-estater mat-header-row {
        margin: 0px 0;
        padding: 0;
        min-height: 0px;
        background-color: #24cb6b;
        border: 1px solid #dbddea;
    }
    
    .table-estater mat-header-row mat-header-cell {
        /* background-color:white; */
        border-right: 1px solid #dbddea;
    }
    /* .table-estater mat-header-row mat-header-cell:first-child {
        border-left:1px solid #dbddea;
    } */
    
    .table-estater mat-header-row mat-header-cell:last-child {
        border-right: none;
    }
    
    .mat-mdc-header-row {
        top: 0;
        position: sticky;
        z-index: 1;
    }
    
    .table-estater mat-header-cell {
        /* border-right:1px solid #dbddea;
        border-top:1px solid #dbddea;
        border-bottom:1px solid #dbddea; */
        padding: 15px 10px;
        color: #fff;
        font-size: 14px;
        font-family: 'Noto Sans', sans-serif;
    }
    
    .table-estater mat-cell {
        padding: 2px 0px;
        color: #8c8c96;
        font-size: 12px;
        font-family: 'Noto Sans', sans-serif;
        border-right: 1px solid #dbddea;
    }
    /* .table-estater mat-cell:first-child {
        border-left:1px solid #dbddea;
    } */
    
    .table-estater mat-cell:last-child {
        border-right: none;
    }
    
    .blue-button {
        background-color: #16ceed!important;
        color: white!important;
        font-family: 'Noto Sans', sans-serif !important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .blue-button::ng-deep .mat-button-focus-overlay {
        background-color: transparent!important;
    }
    
    .orange-button {
        background-color: #f69230!important;
        color: white!important;
        font-family: 'Noto Sans', sans-serif !important;
        min-width: 171px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .orange-button::ng-deep .mat-button-focus-overlay {
        background-color: transparent!important;
    }
    
    .orange-button-sm {
        background-color: #f69230!important;
        color: white!important;
        font-family: 'Noto Sans', sans-serif !important;
        min-width: 171px!important;
        border: 1px solid #f69230!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .orange-button-sm::ng-deep .mat-button-focus-overlay {
        background-color: transparent!important;
    }
    
    .trans-button-sm {
        background-color: transparent;
        color: #666c86!important;
        font-family: 'Noto Sans', sans-serif !important;
        min-width: 171px!important;
        border: 1px solid #666c86!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .trans-button-sm::ng-deep .mat-button-focus-overlay {
        background-color: transparent!important;
    }
    
    .grey-button {
        background-color: #666c86!important;
        color: white!important;
        font-family: 'Noto Sans', sans-serif!important;
        min-width: 171px!important;
        border-radius: 0px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .grey-button::ng-deep .mat-button-focus-overlay {
        background-color: transparent!important;
    }
    
    .grey-button-sm {
        background-color: #666c86!important;
        color: white!important;
        font-family: 'Noto Sans', sans-serif!important;
        min-width: 100px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .grey-button-sm::ng-deep .mat-button-focus-overlay {
        background-color: transparent!important;
    }
    
    .grey-button-sm.disabled,
    .grey-button-sm:disabled {
        background-color: #d7d9e4!important;
        color: white!important;
    }
    
    .ellipsisoverflow {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .create-dialog .mat-mdc-dialog-container {
        padding: 0;
        margin: 0;
        height: auto;
        overflow: inherit;
    }
    
    .create-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
        padding: 10px 30px;
        margin: 0;
    }
    
    .photo-dialog .mat-mdc-dialog-container {
        padding: 0;
        margin: 0;
        height: auto;
        overflow: inherit;
    }
    
    .photo-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
        padding: 10px 30px;
        margin: 0;
    }
    
    .create-role .mat-mdc-dialog-container {
        padding: 0;
        margin: 0;
        height: auto;
        overflow: inherit;
    }
    
    .create-role .mat-mdc-dialog-container .mat-mdc-dialog-content {
        padding: 0px;
        margin: 0;
    }
    
    .bleft {
        border-right: 1px solid #d7dbe6;
        padding: 0 10px;
        height: inherit;
    }
    
    .cleft {
        padding: 0 10px;
        height: inherit;
    }
    
    .crumb-c {
        border-bottom: 1px solid #d7dbe6;
        height: 70px;
        padding: 10px 0px;
        margin-bottom: 20px!important;
    }
    
    .v-bar {
        height: 40px;
        width: 1px;
        margin: 0 10px;
        border-right: 1px solid #5c658e;
    }
    
    .page-content {
        height: 50px;
        background-color: #666c86;
        color: white;
    }
    
    .page-content div {
        padding: 0 10px;
    }
    
    .actionbar {
        background: white;
        padding: 10px 5px;
    }
    
    .greenbutton {
        background-color: #23cb6b!important;
        color: white!important;
    }
    
    .bluebutton {
        background-color: #16cded!important;
        height: 50px!important;
        color: white!important;
        min-width: 40px!important;
        border-radius: 0px!important;
    }
    
    .alertbutton {
        background-color: #fc7c32!important;
        height: 50px!important;
        color: white!important;
        min-width: 40px!important;
        border-radius: 0px!important;
    }
    
    .orangebutton {
        background-color: #f69230!important;
        height: 50px!important;
        color: white!important;
        min-width: 40px!important;
        border-radius: 0px!important;
    }
    
    .obutton {
        background-color: #f69230!important;
        color: white!important;
    }
    
    .mat-mdc-form-field {
        margin: 8px 0!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-focused .mat-form-field-label {
        font-size: 16px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
        font-size: 16px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label-wrapper {
        top: -1.5em!important;
        padding-top: 1.84375em!important;
    }
    
    .mat-column-Role_Assign {
        width: 15%!important;
    }
    
    .mat-column-Actions {
        width: 10%!important;
        text-align: center!important;
    }
    
    .mat-column-Actions td {
        text-align: center;
    }
    
    .total-count {
        margin-bottom: 20px!important;
    }
    
    .total-count span {
        color: #868484;
    }
    
    .mat-column-sno {
        width: 5%!important;
    }
    
    .table-row {
        height: 30px!important;
    }
    
    .mtc-header {
        padding: 0px;
        background-color: #f69230;
        color: white;
        font-size: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }
    
    .mat-mdc-tooltip {
        font-size: 12px!important;
    }
    
    .mat-mdc-dialog-actions {
        margin-bottom: 0px!important;
    }
    
    .mat-mdc-dialog-actions button {
        width: 120px!important;
        border-radius: 3px!important;
        border: 1px solid rgb(198, 198, 198);
    }
    
    .calc-h {
        height: calc( 100vh - 255px)!important;
        overflow: auto!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-mdc-slide-toggle .mat-slide-toggle-thumb {
        background-color: rgb(198, 198, 198)!important;
        position: relative!important;
        top: 4px!important;
        left: 2px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
        background-color: #2bc36b!important;
        position: relative!important;
        top: 4px!important;
        left: 10px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: white!important;
        border: 1px solid rgb(198, 198, 198)!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-bar {
        background-color: white!important;
        border: 1px solid rgb(198, 198, 198)!important;
        position: relative!important;
        width: 50px!important;
        height: 25px!important;
        flex-shrink: 0!important;
        border-radius: 30px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-mdc-slide-toggle .mat-slide-toggle-ripple {
        position: absolute!important;
        top: calc(50% - 9px)!important;
        left: calc(50% - 13px)!important;
        height: 44px!important;
        width: 44px!important;
        z-index: 1!important;
        pointer-events: none!important;
        display: none!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
    .mat-progress-bar-fill:after {
        background-color: #ff9800!important;
    }
    
    .mat-mdc-slide-toggle.mat-checked .mat-ripple-element {
        background-color: #2bc36b!important;
    }
    
    .mat-mdc-progress-spinner circle,
    .mat-mdc-progress-spinner circle {
        stroke: #f69230!important;
    }
    
    .table-spinner {
        position: absolute!important;
        top: calc(100vh - 55%)!important;
        right: calc(100vw - 55%)!important;
    }
    
    .mat-mdc-button:active {
        outline: none!important;
        border: none!important;
    }
    
    .mat-mdc-button:focus {
        outline: none!important;
        border: none!important;
    }
    
    .mat-mdc-button {
        outline: none!important;
        border: none!important;
    }
    
    .mat-mdc-icon-button {
        outline: none!important;
        border: 0!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-arrow {
        color: orange!important;
    }
    
    .mat-mdc-paginator-container {
        height: 45px!important;
        min-height: 0px!important;
    }
    
    .mat-mdc-paginator-range-label {
        border: 1px solid #dbdeea!important;
        border-radius: 50px!important;
        background-color: white!important;
        padding: 1px 8px!important;
        margin: 0px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .user-toggle .mat-slide-toggle-bar {
        background-color: rgb(1, 191, 223)!important;
        border: none!important;
        position: relative!important;
        width: 130px!important;
        height: 40px!important;
        flex-shrink: 0!important;
        border-radius: 30px!important
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .user-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: #2ab934!important;
        border: none!important;
        position: relative!important;
        width: 110px!important;
        height: 40px!important;
        flex-shrink: 0!important;
        border-radius: 30px!important
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .user-toggle .mat-slide-toggle-thumb {
        background: white!important;
        position: relative!important;
        top: 8px!important;
        left: 5px!important;
        height: 30px;
        width: 30px;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .user-toggle.mat-checked .mat-slide-toggle-thumb {
        background: white!important;
        position: relative!important;
        top: 8px!important;
        left: 56px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .user-toggle .mat-slide-toggle-content {
        position: absolute!important;
        color: white!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: #16ceed!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: #f69230!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
        background-color: #f69230!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
        background-color: #16ceed!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
        background-color: #f69230!important;
    }
    
    .loader-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999;
    }
    
    @media screen and (max-width: 500px) and (min-width: 280px) and (orientation: portrait) {
        .orange-button {
            width: auto;
            min-width: 88px!important;
        }
        .grey-button {
            width: auto;
            min-width: 88px!important;
        }
    }
    
    .form-input {
        width: 94%;
        font-size: 14px;
        padding: 10px 5px;
        border: 1px solid #ccc;
        font-family: 'Noto Sans', sans-serif!important;
    }
    
    .action-icon .mat-icon {
        width: 18px;
    }
    
    .act-container {
        padding: 0px 10px 10px 10px!important;
    }
    
    .act-container .r-mv {
        padding-bottom: 10px;
        color: #616474;
        border-bottom: 1px solid #dbdeea;
    }
    
    .mat-mdc-dialog-container .matpopup-header {
        background-color: #f69230;
        color: white;
        font-size: 14px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }
    
    .form-select-l {
        padding: 5px 35px 5px 15px;
        font-size: 12px;
        border: 1px solid #dbdeea;
        height: 44px;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 116px!important;
        height: 34px!important;
        outline: 0!important;
        padding: 0px!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value {
        vertical-align: middle!important;
    }
    
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-trigger {
        height: 100%!important
    }
    
    .form-input-l {
        border-radius: 3px!important;
        height: 40px;
        min-width: 280px;
        padding-left: 10px;
        outline: none;
        border: 1px solid #dbdeea;
        font-size: 12px;
    }
    
    .form-input-m {
        height: 30px;
        padding-left: 10px;
        outline: none;
        border: 1px solid #dbdeea;
        font-size: 12px;
    }
    
    .form-select-m {
        padding: 5px 35px 5px 15px;
        font-size: 12px;
        border: 1px solid #dbdeea;
        height: 30px;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        appearance: none;
        background: url(/assets/images/arrowdrop.png) 96% / 8% no-repeat #ffffff;
    }
    
    .form-input-container .mat-mdc-form-field {
        margin: 0!important;
    }
    
    .form-input-container .mat-mdc-checkbox {
        margin: 10px 0;
    }
    /* width */
    
     ::-webkit-scrollbar {
        width: 6px;
    }
    /* Track */
    
     ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 4px;
    }
    /* Handle */
    
     ::-webkit-scrollbar-thumb {
        background: #5555;
        border-radius: 4px;
    }
    /* Handle on hover */
    
     ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    /* mobile view */
    /* width */
    
     ::-webkit-scrollbar {
        width: 6px !important;
    }
    /* Track */
    
     ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent !important;
        border-radius: 0px;
    }
    /* Handle */
    /* ::-webkit-scrollbar-thumb {
        background: transparent !important;
        border-radius: 0px !important;
    } */
    /* Handle on hover */
    /* ::-webkit-scrollbar-thumb:hover {
        background: transparent !important;
    } */
    /* @media screen and (max-width: 768px) and (min-width: 280px) and (orientation: portrait) {
        ::-webkit-scrollbar {
            width: 0px !important;
        }
        ::-webkit-scrollbar-track {
            border-radius: 0px;
        }
        ::-webkit-scrollbar-thumb {
            background: transparent !important;
            border-radius: 0px !important;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: transparent !important;
        }
    } */
    
    button {
        padding: 8px 16px;
        border-radius: 3px;
        border: 0px;
        background: #00c2de;
        color: #fff;
    }